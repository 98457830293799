.section:target {
    padding-top: 100px;
}

.nav-container {
    position: absolute;
    margin: 20px;
    width: auto;
    color: white;
}

.img-header {
    width: 100%;
    height: auto;
    margin:auto;
}

.header {
    margin:auto;
    height: auto;
    max-width: 10000px;
}

.nav-custom {
    color: white;
    background-color: black;
}
.nav-link {
    color: white;
}

.grid-container {
    max-width: 200%;
}

.col-top {
    background-color:rgb(239, 236, 236, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    opacity: 0.9;
}

.col-middle {
    background-color:rgb(239, 236, 236, 1);
    opacity: 0.9;
}

.col-bottom {
    background-color:rgb(239, 236, 236, 1);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    opacity: 0.9;
}

.boot-carousel{
    width: 100vw;
    margin: auto;
}

.grid-img {
    width: 100%;
    height: 30vh;

}

.grid-para {
    width: 100%;
    font: rooney;
    /* background-image: radial-gradient( rgb(205, 241, 255, 1), rgba(230, 249, 255, 1), rgba(224, 224, 224,0)); */
}

.calendar {
    height: 90vh;
    padding-bottom: 10vh;
    padding-top: 150px;
    max-width: 1000px;
}

.container-contact {
    max-width: 600px;
    padding-top: 50px;
    margin: auto;
}

@media (min-width: 200px) {
    .container-contact{
        width: 90%;
    }
}

.container-aboutus {
    max-width: 600px;
    padding-top: 5vh;
    padding-bottom: 5vh;
    margin: auto;
    font-family: 'Libre Baskerville';
}

.img-family {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.img-footer{
    width: 100%;
    margin: auto;
}

.my-footer{
    margin: auto;
    max-width: 1000px;
}

.footer-text{
    font-size: 6px;
    margin: auto;
}