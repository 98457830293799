.carousel-container {
    margin: auto;
}

.img-carousel {
    height: auto;
    width: 95%;
    max-height: 80vh;
    display: block;
    margin: auto;
}
.carousel {
    padding-top: 150px;
    max-width: 1000px;
    margin: auto;
}


.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}


/* @media (min-width: 200px) {
    .carousel {
        height: 45vh;
        min-width: 40vw;
        justify-content: center;
        align-items: center;
    }
    .slide-container {
        padding-top: 50px;
        padding-bottom: 45px;
        min-width: 100%;
    }
} */