.footer-container{
    max-width: 10000px;
    margin:auto;
}

.footer-img {
    background-image: url("../images/condo/beach.jpeg");
    height: 40vh;
    background-size: cover;

}

.footer-col {
    background-color: #fbfaf7;
}

.footer-icon {
    height: 3vh;
    display: block;
    margin-left: 40px;
}

.footer-icon-row {
    margin: auto;
    margin-top: 3vh;
}

.footer-link {
    color: black;
    padding-left: 10px;
}

.footer-header {
    margin-top: 4vh;
    margin-bottom: 8vh;
    text-align: center;
}