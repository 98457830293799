.opener-header {
    text-align: center;
    font-family: 'Libre Baskerville';
}
.opener-para {
    text-align: justify;
    font-family: 'Libre Baskerville';
    padding-left: 20px;
    padding-right: 20px;
}

.opener-button {
    background-color: #000000;
    border-radius: 10px;
    position: absolute;
    height: 60px;
    width: 140px;
    margin: 0;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.opener {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 50px;
    font-family: 'Libre Baskerville';
    font-size: 16pt;
}

.opener-logo {
    height: 20vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
}