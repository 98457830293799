.bed {
    background-color:rgb(239, 236, 236, 1);
    border-radius: 8px;
    max-width: 500px;
    min-height: 15vh;
    opacity: 0.7;
}

.symbol {
    max-width: 5vw;
    max-height: 4vh;
}

.li-icon {
    height: 2vh;
    width: 1vw;
    margin-right: 10px;
}

.li-amenities {
    list-style-type: none;
}

.amenities {
    max-width: 80%;
    font-family: 'Libre Baskerville';
}

@media (min-width: 200px) {
    .amenities{
        width: 800px;
    }
    .col-amenities {
        width: 800px;
    }
    .grid-container {
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
}